// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import React, { createContext, useContext, useEffect, useState } from 'react';
import { json, redirectDocument } from '@remix-run/node';
import { useNavigation, LiveReload, useRouteLoaderData } from '@remix-run/react';
import { useLoaderData, Links, Meta, Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import { optionalOrg, requireUser } from './services/session.server.ts';
import tailwind from './tailwind.css';
import { Header } from './components/header.tsx';
import { Button } from './components/ui/button.tsx';
import { RefreshCcwDot } from 'lucide-react';
import { withSentry } from '@sentry/remix';
import { getEnv } from './services/env.server.ts';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { lazy } from 'react';
import rdtStylesheet from 'remix-development-tools/index.css';
import { getPatientPrograms } from './services/patient.service.ts';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle } from './components/ui/alert-dialog.tsx';
import { getServerTiming } from './services/timing.server.ts';
import { userHasPermission, userHasRole } from './utils.ts';
const RemixDevTools = process.env.NODE_ENV === 'development' ? lazy(() => import('remix-development-tools')) : null;
const _links = [{
  rel: 'preconnect',
  href: 'https://fonts.googleapis.com'
}, {
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
}, {
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap'
}, {
  rel: 'stylesheet',
  href: tailwind
}];
if (process.env.NODE_ENV === 'development') {
  _links.push({
    rel: 'stylesheet',
    href: rdtStylesheet
  });
}
export const links = () => _links;
export const meta = () => {
  return [{
    charset: 'utf-8'
  }, {
    title: 'Nexus'
  }, {
    viewport: 'width=device-width,initial-scale=1'
  }];
};
export const shouldRevalidate = ({
  formMethod,
  nextUrl
}) => {
  return formMethod == 'POST' || nextUrl.pathname == '/login';
};
export const loader = async ({
  request
}) => {
  // @todo don't reload every sub page
  const url = new URL(request.url);
  if (url.hostname == 'beta.encorenexus.com') {
    const redirect_url = `https://app.encorenexus.com${url.pathname}${url.search}`;
    return redirectDocument(redirect_url);
  }
  if (url.pathname.includes('/login/sso/')) {
    const org = url.pathname.split('/')[3];
    const redirect_url = `https://id.encorenexus.com/saml/drupal_login/${org}?returnTo=/app`;
    return redirectDocument(redirect_url);
  }
  const {
    time,
    getServerTimingHeader
  } = getServerTiming();
  let user = null;
  if (!['/login', '/logout', '/oauth/callback'].includes(url.pathname)) {
    user = await time('root#requireUser', async () => await requireUser(request));
  }
  const org = await optionalOrg(request);
  let programs = [];
  if (user) {
    programs = await time('root#getPatientPrograms', async () => await getPatientPrograms(request));
  }
  return json({
    user: user,
    org: org,
    ENV: getEnv(),
    programs: programs
  }, {
    headers: getServerTimingHeader()
  });
};
export function useRootLoaderData() {
  _s();
  return useRouteLoaderData('root');
}
_s(useRootLoaderData, "HP+Y84T8RxtBxKrBpCtBHZ7kTOE=", false, function () {
  return [useRouteLoaderData];
});
export const CensorContext = createContext(null);
function Document({
  children,
  env = {}
}) {
  _s2();
  const [isLoading, setIsLoading] = useState(false);
  const [censor, setCensor] = useState(false);
  const navigation = useNavigation();
  useEffect(() => {
    switch (navigation.state) {
      case 'loading':
        setIsLoading(true);
        break;
      case 'idle':
        setIsLoading(false);
        break;
    }
  }, [navigation]);
  return <html lang="en">
			<head>
				<Meta />
				<Links />
			</head>
			<body className="font-roboto bg-slate-200 relative">
				<CensorContext.Provider value={{
        censor,
        setCensor
      }}>
					{children}
				</CensorContext.Provider>

				<AlertDialog open={navigation.state == 'loading'}>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle className="flex gap-2 items-center justify-center">
								<RefreshCcwDot width={32} height={32} className="animate-spin" />

								<div className="text-2xl">Loading...</div>
							</AlertDialogTitle>
						</AlertDialogHeader>
					</AlertDialogContent>
				</AlertDialog>

				<script dangerouslySetInnerHTML={{
        __html: `window.ENV = ${JSON.stringify(env)}`
      }} />

				<ScrollRestoration />
				<Scripts />
				<LiveReload />
			</body>
		</html>;
}
_s2(Document, "aHkmH4NNS4EhoYtfeBgj9xHiCwo=", false, function () {
  return [useNavigation];
});
_c = Document;
function AdminTools() {
  _s3();
  const {
    censor,
    setCensor
  } = useContext(CensorContext);
  return <div className="p-4 flex gap-2 text-sm content-center items-center">
		<div className="font-bold">admin tools:</div>
		{userHasPermission('content.censor') ? <Button variant="link" onClick={() => setCensor(!censor)} className="">{censor ? "show" : "hide"} phi</Button> : null}
	</div>;
}
_s3(AdminTools, "x7rr5Tzm+wQLsSolZDXg2dufrlc=");
_c2 = AdminTools;
export function App() {
  _s4();
  const {
    user,
    ENV
  } = useLoaderData();
  return <Document env={ENV}>
			{user && <Header />}
			<div id="root">
				<Outlet />
				{/* {user != null && <IdleTimer />} */}
			</div>
			{userHasRole(user, 'administrator') ? <AdminTools /> : null}
		</Document>;
}
_s4(App, "10e+YN3bJRtp90zYLHG5UrgASjI=", false, function () {
  return [useLoaderData];
});
_c3 = App;
export function ErrorBoundary() {
  return <Document>
			<GeneralErrorBoundary />
		</Document>;
}
_c4 = ErrorBoundary;
let AppExport = App;
if (process.env.NODE_ENV === 'development') {
  const {
    withDevTools
  } = require('remix-development-tools');
  AppExport = withDevTools(AppExport);
}
export { headers } from '~/services/defaults.server';
export default _c5 = withSentry(AppExport);
var _c, _c2, _c3, _c4, _c5;
$RefreshReg$(_c, "Document");
$RefreshReg$(_c2, "AdminTools");
$RefreshReg$(_c3, "App");
$RefreshReg$(_c4, "ErrorBoundary");
$RefreshReg$(_c5, "%default%");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;