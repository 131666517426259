// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$(),
  _s4 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header.tsx");
  import.meta.hot.lastModified = "1734540807205.8206";
}
// REMIX HMR END

import { Link, useLocation, useMatches } from '@remix-run/react';
import { useOptionalOrg, useOptionalUser, useUser } from '~/utils.ts';
import { ChevronRight } from 'lucide-react';
import { getNavigation } from '~/services/navigation.service.ts';
import { useContext } from 'react';
import { CensorContext } from '~/root';
import { get } from 'lodash';
const NavLink = ({
  to = null,
  target = '',
  disabled = false,
  active = false,
  children
}) => {
  let classes = 'text-xl py-2.5 px-4 my-0 mx-2.5 border-b-4 border-b-transparent';
  if (disabled) {
    classes += ' text-gray-300';
    return <div className={classes}>{children}</div>;
  }
  classes += ' hover:text-[#05afef] hover:border-b-[#05afef]';
  if (active) {
    classes += ' text-[#05afef] border-b-[#05afef]';
  } else {
    classes += ' text-[#4c4c4c]';
  }
  return <Link to={to} className={classes} target={target}>
			{children}
		</Link>;
};
_c = NavLink;
const HeaderLinks = () => {
  _s();
  const links = getNavigation();
  if (links.length == 0) {
    return <></>;
  }
  const location = useLocation();
  return <div className="flex font-bold uppercase">
			{links.map((link, index) => {
      return <NavLink key={index} to={link.to} active={location.pathname.indexOf(link.to) === 0} target={link.target || '_self'} disabled={link.disabled || false}>
						{link.label}
					</NavLink>;
    })}
		</div>;
};
_s(HeaderLinks, "pkHmaVRPskBaU4tMJuJJpV42k1I=", false, function () {
  return [useLocation];
});
_c2 = HeaderLinks;
const SecondaryTabs = ({
  children
}) => {
  _s2();
  const matches = useMatches();
  const location = useLocation();
  const user = useUser();
  const secondaryTabs = matches.filter(match => match.handle && match.handle.secondaryTabs).reverse().reduce((carry, match) => {
    let current = match.pathname === location.pathname;
    let tabs = match.handle?.secondaryTabs(match, current, matches, user).map(tab => {
      tab.active = location.pathname.indexOf(tab.url) === 0;
      return tab;
    });
    return carry.concat(tabs);
  }, []);
  return <>
			{secondaryTabs && <nav id="secondary"><div className="flex w-full items-center bg-[#05afef] px-8">
					{secondaryTabs.map(({
          label,
          active = false,
          url,
          disabled = false,
          icon = null
        }, index) => {
          let classes = 'py-2 px-4 my-0 mx-2.5 uppercase font-bold flex gap-2 items-center';
          if (disabled) {
            classes += ' text-gray-300';
            return <div className={classes}>{label}</div>;
          }
          if (active) {
            classes += ' text-white border-b-2 border-b-white';
          } else {
            classes += ' text-[#4c4c4c] hover:text-white';
          }
          return <Link to={url} className={classes} key={index}>
									{icon && <>{icon}</>}
									{label}
								</Link>;
        })}
				</div></nav>}
		</>;
};
_s2(SecondaryTabs, "dK68gd9E7TSUDMZ/t951bFvlMfg=", false, function () {
  return [useMatches, useLocation, useUser];
});
_c3 = SecondaryTabs;
const Breadcrumbs = ({
  children
}) => {
  _s3();
  const matches = useMatches();
  const location = useLocation();
  const {
    censor
  } = useContext(CensorContext);
  const links = matches.filter(match => match.handle && match.handle.breadcrumb).reduce((carry, match) => {
    let current = match.pathname === location.pathname;
    let links = match.handle?.breadcrumb(match, current, matches);
    if (links.length === 0) {
      return carry;
    }
    links = links.reverse();
    if (current) {
      links[0].url = null;
    }
    links = links.reverse();
    return carry.concat(links);
  }, []);
  return <div className="flex items-center gap-2 ">
			{/* <div className="flex content-center items-center">
    <Home width={18} height={18} />
    </div> */}
			{links.map((link, i) => {
      const censorContent = get(link, 'phi', false);
      return <div key={i} className="flex items-center gap-2">
						{i > 0 && <ChevronRight width={18} height={18} />}

						{!link.url && <span className={`${censorContent && censor ? 'censor-content' : null}`}>{link.label}</span>}
						{link.url && <Link to={link.url} key={i} className={`font-semibold text-[#05afef] ${censorContent && censor ? 'censor-content' : null}`}>
								{link.label}
							</Link>}
					</div>;
    })}
		</div>;
};
_s3(Breadcrumbs, "sXFhZIq19JhwUM8py+reaEJn/lM=", false, function () {
  return [useMatches, useLocation];
});
_c4 = Breadcrumbs;
export const Header = () => {
  _s4();
  const org = useOptionalOrg();
  const user = useOptionalUser();
  return <header><div className="header mb-2 print:hidden">
			<nav id="primary"><div className="border-b-4 border-b-blue-400 bg-white" role="navigation">
				<div className="flex items-center">
					<div className="mx-4">
						<Link className="block" to="/patients">
							<img src="/images/nexus-logo.svg" className="h-12 align-middle" />
						</Link>
					</div>

					<HeaderLinks />

					<div className="flex flex-auto items-center justify-end text-sm divide-x-4 text-[#3c3c3c]">

						<Link to="kb" target="_blank" className="px-2">
							Knowledge Base
						</Link>
						<Link to="https://encorehc.atlassian.net/servicedesk/customer/portals" target="_blank" className="px-2">
							Help
						</Link>
						{user && <div className="px-2">
								{user.account.full_name || user.preferred_username}
							</div>}

						{org && <Link to="/organizations" className="px-2">
								{org.title}
							</Link>}

						<Link to="/logout" className="m-2.5 rounded bg-[#3c3c3c] px-2.5 py-1.5 text-white no-underline">
							Sign out
						</Link>
					</div>
				</div>
			</div></nav>

			<SecondaryTabs />
			<nav id="breadcrumbs"><div className="w-full px-10 pt-2">
				<Breadcrumbs className="bg-transparent" style="background: transparent" />
			</div></nav>
		</div></header>;
};
_s4(Header, "kJcSpXeuIgjU5U6LgFG8tAEevms=", false, function () {
  return [useOptionalOrg, useOptionalUser];
});
_c5 = Header;
var _c, _c2, _c3, _c4, _c5;
$RefreshReg$(_c, "NavLink");
$RefreshReg$(_c2, "HeaderLinks");
$RefreshReg$(_c3, "SecondaryTabs");
$RefreshReg$(_c4, "Breadcrumbs");
$RefreshReg$(_c5, "Header");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;